<template>
  <div class="service-mobile" v-if="isMobile">
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="column-me center-all left-div">
          <div class="title1">服务支持</div>
          <div class="title2">SERVICE</div>
        </div>
        <div class="right-div row-me row-center flex-wrap flex1">
          <div class="row-me row-center item-title" v-for="(item,index) in titleArray" :key="index">
            <div class="title0 flex1" :class="{'title0-active':tabIndex===index}"
                 @click="clickIndex(item,index)">
              {{ item.title }}
            </div>
            <div class="tab-line" v-if="index!==titleArray.length-1"></div>
          </div>
        </div>
      </div>
      <div class="row-me center-all margin-top60">
        <img src="../assets/img/title_line.png" class="img-line">
        <div class="column-me row-center margin-left10 margin-right10" v-if="selectItem">
          <div class="title">{{ selectItem.title }}</div>
        </div>
        <img src="../assets/img/title_line.png" class="img-line">
      </div>
      <div class="row-me center-all">
        <div class="subtitle">{{ selectItem.en_title }}</div>
      </div>
      <div class="content" v-html="selectItem.content" v-if="selectItem"></div>
    </div>
  </div>
  <div class="service" v-else>
    <img src="../assets/img/banner_home.png" class="img-banner">
    <div class="main-content">
      <div class="row-me tab-div">
        <div class="row-me center-all left-div">
          <div class="title1">服务支持</div>
          <div class="title2 margin-left20">SERVICE</div>
        </div>
        <div class="right-div row-me row-center flex-wrap flex-end flex1">
          <div class="row-me row-center item-title" v-for="(item,index) in titleArray" :key="index">
            <div class="title0" :class="{'title0-active':tabIndex===index}" @click="clickIndex(item,index)">
              {{ item.title }}
            </div>
            <div class="tab-line" v-if="index!==titleArray.length-1"></div>
          </div>
        </div>
      </div>
      <div class="row-me center-all margin-top90">
        <img src="../assets/img/title_line.png" class="img-line">
        <div class="column-me row-center margin-left60 margin-right60" v-if="selectItem">
          <div class="title">{{ selectItem.title }}</div>
          <div class="subtitle">{{ selectItem.en_title }}</div>
        </div>
        <img src="../assets/img/title_line.png" class="img-line">
      </div>
      <div class="content" v-html="selectItem.content" v-if="selectItem"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tabIndex: 0,
      titleArray: [],
      selectItem: null,
      isMobile: false,
    }
  },
  // html加载完成之前
  created() {

  },
  // html加载完成后执行。
  mounted() {
    this.isMobile = sessionStorage.getItem('mobile') === '1'
  },
  // 事件方法执行
  methods: {
    clickIndex(item, index) {
      this.selectItem = item
      this.$router.push({
        path: '/service',
        query: {
          index: index
        }
      })
    },
    getService() {
      this.$postRequest('/api/index/getService', {}).then((res) => {
        if (res) {
          this.titleArray = res.data
          if (this.titleArray.length > 0) {
            this.selectItem = this.titleArray[this.tabIndex]
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    $route: {
      handler(to, from) {
        if (to.name.indexOf('service') !== -1) {
          if (this.$route.query.index) {
            this.tabIndex = Number(this.$route.query.index)
          }
          this.getService()
        }
      },
      deep: true
    },
  },
  activated() {
    if (this.$route.query.index) {
      this.tabIndex = Number(this.$route.query.index)
    }
    this.getService()
  }
}
</script>

<style lang="scss" scoped>
.service-mobile {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 20px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 130px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;
        padding: 10px 0;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-top: 10px;
        }
      }

      .right-div {
        padding: 15px 10px;

        .item-title {
          margin-bottom: 10px;
        }
      }

      .title0 {
        font-size: 18px;
        color: #999999;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 20px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 80px;
    }

    .title {
      font-size: 23px;
      color: #000000;
      letter-spacing: 2px;
      text-align: center;
      margin-bottom: 10px;
    }

    .subtitle {
      font-size: 18px;
      color: #666666;
    }

    .content {
      font-size: 20px;
      color: #333333;
      line-height: 30px;
      margin-top: 50px;

      /deep/ img {
        max-width: 100%;
        object-fit: scale-down;
        display: block;
        margin: auto;
      }

      /deep/ p {
        margin: 0 !important;
      }
    }
  }
}

.service {
  .img-banner {
    width: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .main-content {
    padding: 80px 100px;

    .tab-div {
      background: #F4F4F4;
      border-radius: 10px;

      .left-div {
        width: 330px;
        background: #000000;
        border-radius: 10px 0px 0px 10px;

        .title1 {
          font-size: 23px;
          color: #FFFFFF;
        }

        .title2 {
          font-size: 15px;
          color: #FFF10C;
          margin-bottom: -15px;
        }
      }

      .right-div {
        padding: 25px 30px;

        .item-title {
          line-height: 45px;
        }
      }

      .title0 {
        font-size: 26px;
        color: #999999;
        margin-left: 30px;
        text-align: center;
        cursor: pointer;

        &:hover {
          font-weight: bold;
        }

        &-active {
          color: #666666;
          font-weight: bold;
        }
      }

      .tab-line {
        width: 3px;
        height: 30px;
        margin-left: 30px;
        background: #999999;
        border-radius: 10px;
      }
    }

    .img-line {
      width: 145px;
      object-fit: scale-down;
      margin-bottom: 10px;
    }

    .title {
      font-size: 48px;
      color: #000000;
      letter-spacing: 5px;
      text-align: center;
    }

    .subtitle {
      font-size: 24px;
      color: #666666;
    }

    .content {
      width: 100%;
      font-size: 26px;
      color: #333333;
      line-height: 40px;
      margin-top: 80px;

      /deep/ img {
        max-width: 100%;
        object-fit: scale-down;
        display: block;
        margin: auto;
      }

      /deep/ p {
        margin: 0 !important;
      }
    }
  }
}
</style>
